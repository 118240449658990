import { checkIBAN } from "../plugins/iban.js";
const validate = (key) => {
  var map = new Map();
  map.set("housenumber", [
    (val) =>
      (val !== null && val !== "" && /^[1-9][0-9]*$/.test(val)) ||
      "Vul een (geldig) huisnummer in",
  ]);
  map.set("postcode", [
    (val) =>
      (val !== null && val !== "" && /^[1-9][0-9]{3}[A-Z]{2}$/.test(val)) ||
      "Vul een postcode in",
  ]);
  map.set("lowerCase", [
    (val) =>
      /^([a-z']([ ]*[a-z'])*)?$/.test(val) ||
      "Alleen Kleineletters of enkele quotes",
  ]);
  map.set("initials", [
    (val) => (val && val.length > 0) || "Vul initialen in",
    (val) =>
      /^([A-Z]\.)*$/.test(val) ||
      "Ongeldig initialen. Alleen hoofdletters en punten toegestaan. Bv: A.B.C.",
  ]);
  map.set("gender", [(val) => (val && val.length > 0) || "Vul aanhef in"]);
  map.set("required", [
    (val) => (val !== null && val !== "") || "Vul een waarde in",
  ]);
  map.set("required50", [
    (val) => (val !== null && val !== "") || "Vul een waarde in",
    (val) =>
      val.length <= 50 || "Huidige waarde mag niet langer zijn dan 50 tekens",
  ]);
  map.set("required100", [
    (val) => (val !== null && val !== "") || "Vul een waarde in",
    (val) =>
      val.length <= 100 || "Huidige waarde mag niet langer zijn dan 100 tekens",
  ]);
  map.set("phone", [
    (val) =>
      (val !== null && val !== "" && /^(0[0-9]{9})$/.test(val)) ||
      "Ongeldig telefoonnummer. Alleen cijfers toegestaan. Format: 0613371337",
  ]);
  map.set("ean", [
    (val) =>
      /^(8[0-9]{17})?$/.test(val) ||
      "Ongeldig EAN nummer. Een EAN nummer bestaat altijd uit 18 cijfers beginnend met een 8.",
  ]);
  map.set("iban", [
    (val) => (val !== null && val !== "") || "Vul een waarde in",
    (val) => checkIBAN(val, false) == "OK" || "Vul een valide IBAN in",
  ]);
  map.set("iban-optional", [
    (val) => checkIBAN(val, true) == "OK" || "Vul een valide IBAN in",
  ]);
  map.set("iban-masked", [
    (val) => val.match(/^[*]{11}[0-9]{3}$/) || "De masked IBAN klopt niet",
  ]);
  map.set("iban-masked-optional", [
    (val) =>
      val.match(/^[*]{11}[0-9]{3}$/) ||
      val == "" ||
      "De masked IBAN klopt niet",
  ]);
  map.set("requiredConsumption", [
    (val) =>
      (val !== null && val !== "" && /^[1-9][0-9]*$/.test(val)) ||
      "Vul een positief verbruik in",
  ]);
  map.set("consumption", [
    (val) => /^([1-9][0-9]*)?$/.test(val) || "Vul een positief verbruik in",
  ]);
  map.set("kvk", [
    (val) =>
      /^\d{8}$/.test(val) || " Vul een valide KVK nummer in van 8 cijfers",
    (val) =>
      ["12345678", "87654321", "00000000"].includes(val) == false ||
      " De ingevulde KVK nummer is niet toegestaan",
  ]);
  map.set("btw_nummer", [
    (val) =>
      /^(NL)?\d{9}B\d{2}$/.test(val) ||
      " Ongeldig BTW nummer. Hou dit format aan NL001234567B01.",
  ]);

  return map.get(key);
};

const validator = (key, val) => {
  var tmp = [];
  if (Array.isArray(key)) {
    tmp = key;
  } else {
    tmp = validate(key);
  }
  console.log(tmp);
  if (tmp.length) {
    for (var i = 0; i < tmp.length; i++) {
      console.log(tmp[i]);
      const returnVal = tmp[i](val);
      console.log(returnVal);
      if (returnVal !== true) {
        return returnVal;
      }
    }
  }
  return true;
};

export { validate, validator };
