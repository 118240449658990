/* eslint-disable no-redeclare */
var bigInt = (function () {
  var e = 1e7,
    t = 7,
    n = { positive: !1, negative: !0 },
    r = function (e, t) {
      var n = e.value,
        r = t.value,
        i = n.length > r.length ? n.length : r.length;
      for (var s = 0; s < i; s++) (n[s] = n[s] || 0), (r[s] = r[s] || 0);
      for (var s = i - 1; s >= 0; s--) {
        if (n[s] !== 0 || r[s] !== 0) break;
        n.pop(), r.pop();
      }
      n.length || ((n = [0]), (r = [0])), (e.value = n), (t.value = r);
    },
    i = function (e, s) {
      if (typeof e == "object") return e;
      e += "";
      var u = n.positive,
        a = [];
      e[0] === "-" && ((u = n.negative), (e = e.slice(1)));
      var e = e.split("e");
      if (e.length > 2) throw new Error("Invalid integer");
      if (e[1]) {
        var f = e[1];
        f[0] === "+" && (f = f.slice(1)), (f = i(f));
        if (f.lesser(0))
          throw new Error("Cannot include negative exponent part for integers");
        while (f.notEquals(0)) (e[0] += "0"), (f = f.prev());
      }
      (e = e[0]), e === "-0" && (e = "0");
      var l = /^([0-9][0-9]*)$/.test(e);
      if (!l) throw new Error("Invalid integer");
      while (e.length) {
        var c = e.length > t ? e.length - t : 0;
        a.push(+e.slice(c)), (e = e.slice(0, c));
      }
      var h = o(a, u);
      return s && r(s, h), h;
    },
    s = function (e, t) {
      var e = o(e, n.positive),
        t = o(t, n.positive);
      if (e.equals(0)) throw new Error("Cannot divide by 0");
      var r = 0;
      do {
        var i = 1,
          s = o(e.value, n.positive),
          u = s.times(10);
        while (u.lesser(t)) (s = u), (i *= 10), (u = u.times(10));
        while (s.lesserOrEquals(t)) (t = t.minus(s)), (r += i);
      } while (e.lesserOrEquals(t));
      return { remainder: t.value, result: r };
    },
    o = function (f, l) {
      var c = { value: f, sign: l },
        h = {
          value: f,
          sign: l,
          negate: function (e) {
            var t = e || c;
            return o(t.value, !t.sign);
          },
          abs: function (e) {
            var t = e || c;
            return o(t.value, n.positive);
          },
          add: function (t, s) {
            var u,
              a = c,
              f;
            s ? (a = i(t)) && (f = i(s)) : (f = i(t, a)), (u = a.sign);
            if (a.sign !== f.sign)
              return (
                (a = o(a.value, n.positive)),
                (f = o(f.value, n.positive)),
                u === n.positive ? h.subtract(a, f) : h.subtract(f, a)
              );
            r(a, f);
            var l = a.value,
              p = f.value,
              d = [],
              v = 0;
            for (var m = 0; m < l.length || v > 0; m++) {
              var g = (l[m] || 0) + (p[m] || 0) + v;
              (v = g >= e ? 1 : 0), (g -= v * e), d.push(g);
            }
            return o(d, u);
          },
          plus: function (e, t) {
            return h.add(e, t);
          },
          subtract: function (t, r) {
            var s = c,
              u;
            r ? (s = i(t)) && (u = i(r)) : (u = i(t, s));
            if (s.sign !== u.sign) return h.add(s, h.negate(u));
            if (s.sign === n.negative)
              return h.subtract(h.negate(u), h.negate(s));
            if (h.compare(s, u) === -1) return h.negate(h.subtract(u, s));
            var a = s.value,
              f = u.value,
              l = [],
              p = 0;
            for (var d = 0; d < a.length; d++) {
              (a[d] -= p), (p = a[d] < f[d] ? 1 : 0);
              var v = p * e + a[d] - f[d];
              l.push(v);
            }
            return o(l, n.positive);
          },
          minus: function (e, t) {
            return h.subtract(e, t);
          },
          multiply: function (t, n) {
            var r,
              s = c,
              u;
            n ? (s = i(t)) && (u = i(n)) : (u = i(t, s)),
              (r = s.sign !== u.sign);
            var a = s.value,
              f = u.value,
              l = [];
            for (var h = 0; h < a.length; h++) {
              l[h] = [];
              var p = h;
              while (p--) l[h].push(0);
            }
            var d = 0;
            for (var h = 0; h < a.length; h++) {
              var v = a[h];
              for (var p = 0; p < f.length || d > 0; p++) {
                var m = f[p],
                  g = m ? v * m + d : d;
                (d = g > e ? Math.floor(g / e) : 0), (g -= d * e), l[h].push(g);
              }
            }
            var y = -1;
            for (var h = 0; h < l.length; h++) {
              var b = l[h].length;
              b > y && (y = b);
            }
            var w = [],
              d = 0;
            for (var h = 0; h < y || d > 0; h++) {
              var E = d;
              for (var p = 0; p < l.length; p++) E += l[p][h] || 0;
              (d = E > e ? Math.floor(E / e) : 0), (E -= d * e), w.push(E);
            }
            return o(w, r);
          },
          times: function (e, t) {
            return h.multiply(e, t);
          },
          divmod: function (e, t) {
            var r,
              u = c,
              a;
            t ? (u = i(e)) && (a = i(t)) : (a = i(e, u)),
              (r = u.sign !== a.sign);
            if (o(u.value, u.sign).equals(0))
              return {
                quotient: o([0], n.positive),
                remainder: o([0], n.positive),
              };
            if (a.equals(0)) throw new Error("Cannot divide by zero");
            var f = u.value,
              l = a.value,
              h = [],
              p = [];
            for (var d = f.length - 1; d >= 0; d--) {
              var e = [f[d]].concat(p),
                v = s(l, e);
              h.push(v.result), (p = v.remainder);
            }
            return h.reverse(), { quotient: o(h, r), remainder: o(p, u.sign) };
          },
          divide: function (e, t) {
            return h.divmod(e, t).quotient;
          },
          over: function (e, t) {
            return h.divide(e, t);
          },
          mod: function (e, t) {
            return h.divmod(e, t).remainder;
          },
          pow: function (e, t) {
            var n = c,
              r;
            t ? (n = i(e)) && (r = i(t)) : (r = i(e, n));
            var s = n,
              f = r;
            if (f.lesser(0)) return u;
            if (f.equals(0)) return a;
            var l = o(s.value, s.sign);
            if (f.mod(2).equals(0)) {
              var h = l.pow(f.over(2));
              return h.times(h);
            }
            return l.times(l.pow(f.minus(1)));
          },
          next: function (e) {
            var t = e || c;
            return h.add(t, 1);
          },
          prev: function (e) {
            var t = e || c;
            return h.subtract(t, 1);
          },
          compare: function (e, t) {
            var s = c,
              o;
            t ? (s = i(e)) && (o = i(t, s)) : (o = i(e, s)), r(s, o);
            if (
              s.value.length === 1 &&
              o.value.length === 1 &&
              s.value[0] === 0 &&
              o.value[0] === 0
            )
              return 0;
            if (o.sign !== s.sign) return s.sign === n.positive ? 1 : -1;
            var u = s.sign === n.positive ? 1 : -1,
              a = s.value,
              f = o.value;
            for (var l = a.length - 1; l >= 0; l--) {
              if (a[l] > f[l]) return 1 * u;
              if (f[l] > a[l]) return -1 * u;
            }
            return 0;
          },
          compareAbs: function (e, t) {
            var r = c,
              s;
            return (
              t ? (r = i(e)) && (s = i(t, r)) : (s = i(e, r)),
              (r.sign = s.sign = n.positive),
              h.compare(r, s)
            );
          },
          equals: function (e, t) {
            return h.compare(e, t) === 0;
          },
          notEquals: function (e, t) {
            return !h.equals(e, t);
          },
          lesser: function (e, t) {
            return h.compare(e, t) < 0;
          },
          greater: function (e, t) {
            return h.compare(e, t) > 0;
          },
          greaterOrEquals: function (e, t) {
            return h.compare(e, t) >= 0;
          },
          lesserOrEquals: function (e, t) {
            return h.compare(e, t) <= 0;
          },
          isPositive: function (e) {
            var t = e || c;
            return t.sign === n.positive;
          },
          isNegative: function (e) {
            var t = e || c;
            return t.sign === n.negative;
          },
          isEven: function (e) {
            var t = e || c;
            return t.value[0] % 2 === 0;
          },
          isOdd: function (e) {
            var t = e || c;
            return t.value[0] % 2 === 1;
          },
          toString: function (r) {
            var i = r || c,
              s = "",
              o = i.value.length;
            while (o--)
              i.value[o].toString().length === 8
                ? (s += i.value[o])
                : (s += (e.toString() + i.value[o]).slice(-t));
            while (s[0] === "0") s = s.slice(1);
            s.length || (s = "0");
            var u = i.sign === n.positive ? "" : "-";
            return u + s;
          },
          toJSNumber: function (e) {
            return +h.toString(e);
          },
          valueOf: function (e) {
            return h.toJSNumber(e);
          },
        };
      return h;
    },
    u = o([0], n.positive),
    a = o([1], n.positive),
    f = o([1], n.negative),
    l = function (e) {
      return typeof e == "undefined" ? u : i(e);
    };
  return (l.zero = u), (l.one = a), (l.minusOne = f), l;
})();

const transArray = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const translateCharacter = (IBAN) => {
  var IBANnr = "",
    i;
  for (i = 0; i < IBAN.length; i++) {
    IBANnr += String(transArray.indexOf(IBAN.charAt(i)));
  }
  return IBANnr;
};
const checkIBAN = (IBAN, optional) => {
  //! Temporarily accept empty IBAN values
  if (optional && !IBAN) return "OK";
  //! Temporarily accept empty IBAN values

  IBAN = IBAN.toUpperCase();
  if (!IBAN.match(/^[0-9A-Z]*$/)) return "Ongeldige tekens gevonden";
  if (IBAN.length > 34 || IBAN.length < 5) return "Ongeldige lengte";
  var country = IBAN.slice(0, 2);
  if (!country.match(/^[A-Z][A-Z]$/)) return "Landcode altijd als Letters";
  var check = IBAN.slice(2, 4);
  var code = IBAN.slice(4);
  var IBANnr = translateCharacter(code + country);
  IBANnr = bigInt(IBANnr + "00");
  var mod = IBANnr.mod(97);
  var IBANcheck = 98 - mod;
  if (check != IBANcheck) return "Controle getal niet goedgekeurd";
  return "OK";
};

function mask(IBAN) {
  if (!IBAN) {
    return "";
  }
  const stars = "***********";
  const maskiban = IBAN.slice(-3);
  return stars + maskiban;
}

export { mask, checkIBAN };
