<template>
  <q-markup-table flat>
    <thead>
      <tr>
        <th class="text-left">Type aansluiting(en)</th>
        <th class="text-center">EAN code(s)</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-left">
          <q-icon name="bolt" style="font-size: 1.1rem" />Stroom
        </td>
        <td
          class="text-center"
          v-for="installation_elec in electricity"
          :key="installation_elec.connect_ean"
        >
          <q-input
            :readonly="readonly"
            :disable="allowManualEan != 'Ja' || !editEans"
            outlined
            dense
            mask="##################"
            :modelValue="
              !editEans
                ? installationElectricity ?? installation_elec.connect_ean
                : installationElectricityProxy
            "
            @change="installationElectricityProxy = $event"
            :rules="validate('ean')"
          />
        </td>
      </tr>
      <tr>
        <td class="text-left">
          <q-icon name="whatshot" style="font-size: 1rem" /> Gas
        </td>
        <td
          class="text-center"
          v-for="installation_gas in gas"
          :key="installation_gas.connect_ean"
        >
          <q-input
            :readonly="readonly"
            :disable="allowManualEan != 'Ja' || !editEans"
            outlined
            dense
            mask="##################"
            :modelValue="
              !editEans
                ? installationGas ?? installation_gas.connect_ean
                : installationGasProxy
            "
            @change="installationGasProxy = $event"
            :rules="validate('ean')"
          />
        </td>
      </tr>
    </tbody>
  </q-markup-table>
  <div class="q-pt-sm q-my-xs row" v-show="inputToggle">
    <div class="col-2">
      <q-btn
        :disabled="readonly"
        color="primary"
        :ripple="false"
        @click="
          () => {
            editEans = !editEans;
            installationElectricityProxy = installationElectricity;
            installationGasProxy = installationGas;
          }
        "
        no-caps
        >EAN's wijzigen</q-btn
      >
    </div>
    <div class="col-6">
      <q-toggle
        :disable="readonly"
        v-show="editEans"
        class="q-pl-lg q-pt-none"
        true-value="Ja"
        false-value="Nee"
        v-model="allowManualEan"
        color="green"
        label="Gaat de Prospect akkoord met het wijzigen van de EANS?"
      />
    </div>
    <div class="col-4">
      <q-btn
        color="primary"
        class="float-right"
        v-show="allowManualEan == 'Ja'"
        :ripple="false"
        @click="saveManualEans"
        no-caps
        >Opslaan</q-btn
      >
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "@vue/reactivity";
import { useStore } from "vuex";
import { validator, validate } from "../../plugins/validation.js";

//* VueUse
const $store = useStore();

//* refs
const editEans = ref(false);

//* Computed
const readonly = computed(
  () => ($store.getters.record?.locked || $store.getters.viewMode) ?? false
);
const view = computed(() => $store.getters.view);
const inputToggle = computed(() => (view.value != "retention" ? true : false));
const gas = computed(() => $store.getters.installationdetails.gas);
const electricity = computed(
  () => $store.getters.installationdetails.electricity
);
const installationElectricityProxy = ref("");
const installationGasProxy = ref("");

const allowManualEan = computed({
  get() {
    return $store.getters.projectSpecific["toestemmingEan"] ?? "Nee";
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "toestemmingEan",
      value: newVal,
    });
  },
});

const installationGas = computed({
  get() {
    return (
      ($store.getters.projectSpecific["EAN Gas"] != "" &&
        $store.getters.projectSpecific["EAN Gas"]) ??
      false
    );
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "EAN Gas",
      value: newVal,
      validated: validator("ean", newVal),
    });
  },
});
const installationElectricity = computed({
  get() {
    return (
      ($store.getters.projectSpecific["EAN Elektra"] != "" &&
        $store.getters.projectSpecific["EAN Elektra"]) ??
      false
    );
  },
  set(newVal) {
    $store.dispatch("setProjectSpecificValue", {
      key: "EAN Elektra",
      value: newVal,
      validated: validator("ean", newVal),
    });
  },
});

installationElectricityProxy.value = installationElectricity.value;
installationGasProxy.value = installationGas.value;

//* methods
const saveManualEans = () => {
  let values = {
    "EAN Elektra": installationElectricityProxy.value,
    "EAN Gas": installationGasProxy.value,
  };

  if (
    installationElectricityProxy.value != installationElectricity.value ||
    installationGasProxy.value != installationGas.value
  ) {
    values.adresInformatieAangepast = "Ja";
  }

  $store.dispatch("setProjectSpecificValues", {
    values,
  });
  editEans.value = false;
};
</script>
